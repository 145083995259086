import Auth from "@aws-amplify/auth";

export async function Init(): Promise<void> {
  const config = {
    stage: "beta",
    aws_cognito_region: "us-east-1",
    aws_cognito_identity_pool_id: "us-east-1:cbb92afc-e464-4133-b032-4ac8c73b730d",
    aws_user_pools_id: "us-east-1_RzY5aiHXO",
    aws_user_pools_web_client_id: "3llqj156u0k4n5sk130v1mkc71",
    Auth: {
      identityPoolId: "us-east-1:cbb92afc-e464-4133-b032-4ac8c73b730d",
      region: "us-east-1",
      userPoolId: "us-east-1_RzY5aiHXO",
      userPoolWebClientId: "3llqj156u0k4n5sk130v1mkc71",
    },
    oauth: {
      domain: `whs-safer-beta-na.auth.us-east-1.amazoncognito.com`,
      redirectSignIn: "https://beta.na.safer.whs.amazon.dev",
      redirectSignOut: "https://beta.na.safer.whs.amazon.dev",
      scope: ["openid", "profile"],
      responseType: "code",
    },
  };
  Auth.configure(config);
}
