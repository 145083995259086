export const extractFilter = (filters) => {
    const extractValue = (filter, key) => {
        const regex = new RegExp(`(?:\`${key}\`|${key})\\s*==\\s*(['"]?)([^'"]+?)\\1`);
        const matches = filter.match(regex);
        return matches ? matches[2] : undefined;
    };

    const extractValues = (filter, key) => {
        // Regex to match 'key in (...)' and capture everything inside the outer parentheses
        const regex = new RegExp(`(?:\`${key}\`|${key})\\s+in\\s*\\(([^()]*(?:\\([^()]*[^()]*\\)[^()]*)*)\\)`, 'i');
        const matches = filter.match(regex);

        if (matches) {
            // Get the content inside parentheses
            const valuesPart = matches[1];
            // Split by comma while respecting nested parentheses
            return valuesPart.split(/,\s*(?=(?:[^()]*\([^()]*\))*[^()]*$)/).map(value => value.trim().replace(/^['"]|['"]$/g, ''));
        }

        return undefined;
    };

    const recap = {
        site: undefined,
        siteType: undefined,
        org: undefined,
        suborg: undefined,
        country: undefined,
        region: undefined,
        metricName: undefined,
        incidentType: undefined,
    };

    const additionalFilters = {};

    for (const filter of filters) {
        let matched = false;

        if (filter.includes('`site`') || filter.includes('site')) {
            recap.site = extractValues(filter, 'site');
            matched = true;
        }

        if (filter.includes('`site_type`') || filter.includes('site_type')) {
            recap.siteType = extractValues(filter, 'site_type');
            matched = true;
        }

        if (filter.includes('`org`') || filter.includes('org')) {
            recap.org = extractValues(filter, 'org');
            matched = true;
        }

        if (filter.includes('`suborg`') || filter.includes('suborg')) {
            recap.suborg = extractValues(filter, 'suborg');
            matched = true;
        }

        if (filter.includes('`country`') || filter.includes('country')) {
            recap.country = extractValues(filter, 'country');
            matched = true;
        }

        if (filter.includes('`region_code`') || filter.includes('region_code')) {
            recap.region = extractValues(filter, 'region_code');
            matched = true;
        }

        if (filter.includes('`metric_name`') || filter.includes('metric_name')) {
            recap.metricName = extractValues(filter, 'metric_name');
            matched = true;
        }

        if (filter.includes('`incident_type`') || filter.includes('incident_type')) {
            recap.incidentType = extractValues(filter, 'incident_type');
            matched = true;
        }

        // If not matched to any specific location field, add to additionalFilters
        //Skip date check which is handled directly
        const skipCheck = filter.includes('year_month') || filter.includes('year_week') || filter.includes('period_name')
        if (!matched && !skipCheck) {
            if (filter.match(/==|<=|>=/)) {
                const [key, value] = filter.split(/==|<=|>=/).map(part => part.trim().replace(/[`'"]/g, ''));
                if (key && value) {
                    additionalFilters[key] = value;
                }
            }

            const regexForAdditional = /\b(\w+)\s+in\s*\((['"]?([^'"]+['"]?\s*,?\s*)+)\)/i;

            const match = filter.match(regexForAdditional);

            if (match) {
                const key = match[1];
                const values = match[2].split(/\s*,\s*/).map(value => value.replace(/[`'"]+/g, ''));

                if (key && values) {
                    additionalFilters[key] = values;
                }
            }
        }
    }

    return { recap, additionalFilters };
};

export const extractPeriodDates = (period) => {
    let updatedFilterWeek = "";
    let startWeek = '';
    let endWeek = '';

    if(period) {
        updatedFilterWeek = period.replace(/(\d{4})-(\d{2})/g, '$1 WK$2');
    }

    if (updatedFilterWeek && updatedFilterWeek.includes("filter_week")) {
        const pattern = /`?filter_week`?\s*>=\s*'(\d{4} WK\d{2})'\s*AND\s*`?filter_week`?\s*<=\s*'(\d{4} WK\d{2})'/;

        const match = updatedFilterWeek.match(pattern);

        if (match) {
            startWeek = match[1];
            endWeek = match[2];
        }
    }

    return { startWeek, endWeek };
};

export const extractMaturityFlag = (filters) => {
    for (const filter of filters) {
        if (filter.includes("`period_maturity_flag`")) {
            const matches = filter.match(/`period_maturity_flag`\s*==\s*(['"]?)([^'"]+)\1/);
            if (matches) {
                const value = matches[2];
                if (value === '1') {
                    return "mature"
                } else if (value === '0') {
                    return 'immature';
                }
            }
        }
    }
    return '';
};



export const getLocationParts = (recap) => {
    const locationParts = [];
    const locations = [recap.region, recap.org, recap.country, recap.suborg, recap.siteType, recap.site]
    if (locations.some(Boolean)) {
        if (locationParts.length === 0) {
            locationParts.push(" in ");
        }

        locations.forEach((location) => {
            if (location) {
                locationParts.push(`<span class="highlight">${location}</span>`);
            }
        });
    }

    let locationResult = locationParts.join(' ');
    locationResult = locationParts.join(' ').trim() === '' ? ' at<span class="highlight"> all sites</span>' : locationResult;

    return { locationResult };
}

// Function to split filters by AND, OR return an array of substrings
export const processFilters = (filters) => {
    let processedFilters = [];

    filters.forEach(filter => {
        const parts = filter.split(/\s*(?:\bAND\b|\bOR\b)\s*/i); // Word boundary ensures we match standalone AND/OR

        processedFilters = processedFilters.concat(parts.map(part => part.trim()));
    });

    return processedFilters;
}

export const questionRecapForInjuryRateTool = (llm) => {
    const filters = processFilters(llm.filters);
    const { recap } = extractFilter(filters)

    const startWeek = extractPeriodDates(llm["filter_week"]).startWeek;
    const endWeek = extractPeriodDates(llm["filter_week"]).endWeek;

    const maturityFlag= extractMaturityFlag(filters);

    let questionRecap = `Here's the rate of `;


    if (maturityFlag != '') {
        questionRecap = questionRecap + `<span class="highlight">${maturityFlag}</span>`;
    }

    questionRecap = questionRecap + ` <span class="highlight">${recap.metricName}</span> `

    const { locationResult } = getLocationParts(recap)
    if (locationResult) {
        questionRecap = questionRecap + locationResult
    }

    questionRecap = questionRecap + ` for the fiscal week <span class="highlight">${startWeek}</span>, through <span class="highlight">${endWeek}</span>.`

    return questionRecap
};

export const questionRecapForBreakdownTool = (llm, label) => {
    const filters = processFilters(llm.filters);
    const { recap, additionalFilters } = extractFilter(filters)

    const startWeek = extractPeriodDates(llm["filter_week"]).startWeek;
    const endWeek = extractPeriodDates(llm["filter_week"]).endWeek;
    let incidentType = recap.incidentType;

    if (incidentType) {
        incidentType = incidentType;
    } else {
        incidentType = `all injuries`
    }

    const total_number = Number(llm["total_breakdown_number"])
    let questionRecap = `A total of <span class="highlight">${total_number} ${incidentType}</span>`

    const { locationResult } = getLocationParts(recap)

    if (locationResult) {
        questionRecap = questionRecap + " occurred" + locationResult
    }

    const dateParts = ` during the period from <span class="highlight">${startWeek}</span> to <span class="highlight">${endWeek}</span>`

    questionRecap = questionRecap + dateParts

    const additionalFilterParts = Object.entries(additionalFilters)
        .map(([key, value]) => `${key}: ${value}`)
        .join(', ');

    if (additionalFilterParts) {
        return questionRecap + ' with ' + `<span class="highlight">${additionalFilterParts}</span>. Here's a full breakdown by <span class="highlight">${label}</span>:`
    }

    return questionRecap + '.' + ` Here's a full breakdown by <span class="highlight">${label}</span>:`
}
