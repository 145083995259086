import { AwsRum, AwsRumConfig } from "aws-rum-web";
import { isLocal } from "./detectEnv";

export let awsRum: AwsRum;

export async function initAwsRum(alias: string) {
  try {
    if (isLocal()) return;

    const config: AwsRumConfig = {
      sessionSampleRate: 1,
      endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
      identityPoolId: "us-east-1:cbb92afc-e464-4133-b032-4ac8c73b730d",
      telemetries: ["errors", "performance", "http"],
      allowCookies: true,
      enableXRay: false,
    };

    const APPLICATION_ID = "df3b85c0-a8e2-4902-ad8b-8e7d8e92f209";
    const APPLICATION_VERSION = "1.0.0";
    const APPLICATION_REGION = "us-east-1";

    awsRum = new AwsRum(
      APPLICATION_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      config,
    );

    awsRum.addSessionAttributes({ alias: alias });
  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
    console.log("RUM client initialize fail with error: ", error);
  }
}

export const sendCustomRUMEvent = (eventName: string, eventData: object) => {
  if (isLocal()) return;
  awsRum.recordEvent(eventName, eventData);
};
